import React, { useImperativeHandle, forwardRef, useRef, useState } from 'react';

import PopconfirmModal from 'modals/PopconfirmModal';
import PageModal from 'components/v2/PageModal';
import { Trash01Icon } from 'components/v2/Icons';
import NavigationHeaderBaseButton from 'components/v2/Navigation/views/NavigationHeaderBaseButton';

import { TPopconfirmModalRef } from 'modals/PopconfirmModal/PopconfirmModal.types';
import { useModal } from 'components/core/Modal';

import TransactionDetailMerchantDataDescriptionModalForm from './views/TransactionDetailMerchantDataDescriptionModalForm';
import {
  useAddOrUpdateMerchantDataDescription,
  useDeleteMerchantDataDescription,
} from './TransactionDetailMerchantDataDescriptionModal.hooks';
import {
  TTransactionDetailMerchantDataDescriptionModalProps,
  TTransactionDetailMerchantDataDescriptionModalState,
} from './TransactionDetailMerchantDataDescriptionModal.types';

function TransactionDetailMerchantDataDescriptionModal({
  modalRef,
}: TTransactionDetailMerchantDataDescriptionModalProps) {
  const ref = useModal();
  const [state, setState] = useState<TTransactionDetailMerchantDataDescriptionModalState>();
  const popconfirmModalRef = useRef<TPopconfirmModalRef>(null);
  const { addOrUpdateLoading, addOrUpdateUserDataDescription } =
    useAddOrUpdateMerchantDataDescription({
      merchantDataId: state?.merchantData?.id,
      transactionId: state?.transactionId as string,
      onSuccess: handleSuccess,
    });
  const { deleteLoading, showDeleteConfirmationModal } = useDeleteMerchantDataDescription({
    merchantDataId: state?.merchantData?.id as string,
    transactionId: state?.transactionId as string,
    onSuccess: handleSuccess,
    popconfirmRef: popconfirmModalRef,
  });

  useImperativeHandle(modalRef, () => ({
    show: (state: TTransactionDetailMerchantDataDescriptionModalState) => {
      setState(state);
      ref.current?.show(true);
    },
  }));

  function handleHeaderRightContent() {
    if (state?.merchantData?.id) {
      return (
        <NavigationHeaderBaseButton onPress={showDeleteConfirmationModal} disabled={deleteLoading}>
          <Trash01Icon />
        </NavigationHeaderBaseButton>
      );
    }
    return <></>;
  }

  async function handleSuccess() {
    ref.current?.show(false);
    await state?.onRequestTransactionDetail();
  }

  return (
    <PageModal
      modalRef={ref}
      shouldHideSideLayoutOnVisibilityChange={false}
      headerRight={handleHeaderRightContent()}
      titleId={state?.merchantData?.id ? 'label.editDescription' : 'label.addDescription'}>
      <TransactionDetailMerchantDataDescriptionModalForm
        onSubmit={addOrUpdateUserDataDescription}
        loading={addOrUpdateLoading}
        defaultValues={{
          description: state?.merchantData?.description,
        }}
      />
      <PopconfirmModal ref={popconfirmModalRef} />
    </PageModal>
  );
}

export default forwardRef(TransactionDetailMerchantDataDescriptionModal);
