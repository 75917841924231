export const STEP_ACTIVE_LINE_DEFAULT_VARIANTS = {
  active: {
    width: '100%',
  },
  inactive: {
    width: 0,
  },
};

export const STEP_ACTIVE_LINE_HALF_VARIANTS = {
  active: {
    width: '50%',
  },
  inactive: {
    width: 0,
  },
};

export const STEP_ACTIVE_LINE_FULL_VARIANTS = {
  active: {
    width: '100%',
  },
  inactive: {
    width: '50%',
  },
};
