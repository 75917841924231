import React, { useImperativeHandle, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import useFetch from 'use-http';

import { useModal } from 'components/core/Modal';
import PageModal from 'components/PageModal';
import PasswordForm from 'components/Form/PasswordForm';
import Box from 'components/core/Box';
import CardItem from 'components/v2/CardItem';
import Text from 'components/core/Text';

import {
  TPasswordFormData,
  TPasswordFormRef,
} from 'components/Form/PasswordForm/PasswordForm.types';
import API_ENDPOINT from 'constants/apiEndpoint';
import normalizeUrl from 'helpers/normalizeUrl';
import { showErrorAlert, showSuccessAlert } from 'helpers/showAlertModal';
import Card3DSType, {
  getLabelIdByCard3DSType,
  getResetSuccessMessageIdByCard3DSType,
} from 'enums/Card3DSType';

import {
  TCardReset3DSecurityAuthenticationModalProps,
  TModalState,
} from './CardReset3DSecurityAuthenticationModal.types';

function CardReset3DSecurityAuthenticationModal({
  modalRef,
  onSuccess,
}: TCardReset3DSecurityAuthenticationModalProps) {
  const ref = useModal();
  const [state, setState] = useState<TModalState>();
  const formRef = useRef<TPasswordFormRef>(null);
  const {
    response,
    loading,
    put: requestUpdate3DSPassword,
  } = useFetch(
    normalizeUrl(API_ENDPOINT.CARD_THREED_SECURITY_METHOD_RESET, {
      pathVariables: { cardId: state?.card?.id as string },
    })
  );

  useImperativeHandle(
    modalRef,
    () => ({
      show: (state) => {
        setState(state);
        ref.current?.show(true);
      },
    }),
    []
  );

  async function handleSubmit(values: TPasswordFormData) {
    try {
      const input = {
        ...state?.values,
        password: values.password,
      };
      const data = await requestUpdate3DSPassword(input);
      if (!response.ok) {
        showErrorAlert({ title: data?.errorMessage });
        return;
      }

      await state?.refetchCardDetail?.();
      showSuccessAlert({
        titleId: getResetSuccessMessageIdByCard3DSType(
          state?.card.threedSecurityMethod.type as Card3DSType
        ),
        onCancel: () => {
          ref.current?.show(false);
          onSuccess();
        },
      });
    } catch (e) {
      showErrorAlert({
        titleId: 'errors.unexpectedError',
      });
      Sentry.captureException(e);
    }
  }

  return (
    <>
      <PageModal
        modalRef={ref}
        headerTitleId={
          state
            ? getLabelIdByCard3DSType(state.card.threedSecurityMethod.type)
            : 'placeholder.empty'
        }
        afterClose={() => setState(undefined)}
        sideLayoutVisible={false}
        shouldHideSideLayoutOnVisibilityChange={false}
        isStaticSmallHeader>
        {state?.card && (
          <>
            <Text id="messages.authenticationPassword.title" variant="largeTitleBold" mb="s" />
            <Text
              id="messages.authenticationPassword.description"
              variant="largeTextRegular"
              mb="l"
            />
            <PasswordForm ref={formRef} onSubmit={handleSubmit} loading={loading}>
              <Box mb="l">
                <CardItem card={state.card} />
              </Box>
            </PasswordForm>
          </>
        )}
      </PageModal>
    </>
  );
}

export default CardReset3DSecurityAuthenticationModal;
