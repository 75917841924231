import React, { memo } from 'react';

import Box from 'components/core/Box';
import { StarIcon } from 'components/Icons';

import { getIconByCurrency } from 'enums/Currency';

import { TLedgerIconProps } from './LedgerIcon.types';
import MigrationBadgeDot from '../MigrationBadgeDot';

function LedgerIcon({
  size = 32,
  currencyCode,
  isMainLedger,
  isMarkedForClosure,
}: TLedgerIconProps) {
  const IconComponent = getIconByCurrency(currencyCode);

  return (
    <Box flexDirection="row" position="relative">
      <IconComponent width={size} height={size} />
      {!isMarkedForClosure && isMainLedger && (
        <Box
          backgroundColor="tertiary"
          position="absolute"
          right={-4}
          bottom={-4}
          width="20px"
          height="20px"
          borderRadius="20px"
          border="1px solid #fff"
          alignment="center">
          <StarIcon width={12} height={12} fill="#FFF" iconColor="whiteBackground" />
        </Box>
      )}
      {isMarkedForClosure && (
        <Box position="absolute" bottom={0} right={0} zIndex={9}>
          <MigrationBadgeDot />
        </Box>
      )}
    </Box>
  );
}

export default memo(LedgerIcon);
