import React from 'react';

import Form from 'components/Form';
import Text from 'components/core/Text';
import Box from 'components/core/Box';
import FieldDigitCode from 'components/FieldDigitCode';

import useForm from 'hooks/useForm';
import NOOP from 'constants/noop';
import { CARD_3DS_STATIC_PASSWORD_LENGTH } from 'constants/card';

import CardStaticPasswordBulkSwitch from './views/CardStaticPasswordBulkSwitch';
import { FORM_VALIDATION_SCHEMA } from './CardStaticPasswordForm.constants';
import {
  TCardStaticPasswordFormData,
  TCardStaticPasswordFormProps,
} from './CardStaticPasswordForm.types';

function CardStaticPasswordForm({
  formButtonProps,
  pageHeaderProps,
  cardsCount,
  onClickCardsCount,
  defaultValues,
  onSubmit,
  isSwitchVisibleForSetBulkStaticPassword,
}: TCardStaticPasswordFormProps) {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<TCardStaticPasswordFormData>({
    schema: FORM_VALIDATION_SCHEMA,
    // @ts-ignore
    defaultValues,
  });

  const staticPassword = watch('staticPassword');

  return (
    <>
      <Text id={pageHeaderProps.titleId} variant="largeTitleBold" mb="s" />
      <Text id={pageHeaderProps.descriptionId} variant="largeTextRegular" mb="l" />
      <Form
        onSubmit={handleSubmit(onSubmit)}
        buttonProps={{
          disabled: staticPassword?.length !== CARD_3DS_STATIC_PASSWORD_LENGTH,
          ...formButtonProps,
        }}
        {...(isSwitchVisibleForSetBulkStaticPassword && {
          buttonExtraContent: (
            <Box display="flex" alignSelf="flex-start" width="100%">
              <CardStaticPasswordBulkSwitch
                labelId="screens.cardSetBulkStaticPassword.switchTitle.withCardsCount"
                name="isBulkSetActive"
                control={control}
                errors={errors}
                cardsCount={cardsCount}
                onPress={onClickCardsCount}
                disabled={defaultValues?.isBulkSetActive}
              />
            </Box>
          ),
        })}>
        <Box alignment="center">
          <FieldDigitCode
            name="staticPassword"
            control={control}
            errors={errors}
            onSubmit={NOOP}
            autoComplete="off"
          />
        </Box>
      </Form>
    </>
  );
}

export default CardStaticPasswordForm;
