import React from 'react';

import { BaseForm, useForm } from 'components/v2/Forms';
import { FieldTextInput } from 'components/v2/Fields';

import { MERCHANT_DATA_DESCRIPTION } from 'constants/regex';

import {
  TTransactionDetailMerchantDataDescriptionModalFormData,
  TTransactionDetailMerchantDataDescriptionModalFormProps,
} from '../TransactionDetailMerchantDataDescriptionModal.types';
import { FORM_VALIDATION_SCHEMA } from '../TransactionDetailMerchantDataDescriptionModal.constants';

function TransactionDetailMerchantDataDescriptionModalForm({
  onSubmit,
  defaultValues,
  loading,
}: TTransactionDetailMerchantDataDescriptionModalFormProps) {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<TTransactionDetailMerchantDataDescriptionModalFormData>({
    schema: FORM_VALIDATION_SCHEMA,
    defaultValues,
  });

  return (
    <BaseForm
      onSubmit={handleSubmit(onSubmit)}
      submitButtonLabelId="button.save"
      submitButtonProps={{
        loading,
        disabled: !isDirty,
      }}>
      <FieldTextInput
        name="description"
        labelId="label.description"
        placeholderId="placeholder.empty"
        control={control}
        errors={errors}
        maxLength={255}
        onFormatValue={(value) => value?.replace(MERCHANT_DATA_DESCRIPTION, '')}
        autoFocus
        marginBottom="xxl"
      />
    </BaseForm>
  );
}

export default TransactionDetailMerchantDataDescriptionModalForm;
