import React from 'react';

import Box from 'components/core/Box';

import { TStepProps } from '../Steps.types';
import {
  STEP_ACTIVE_LINE_DEFAULT_VARIANTS,
  STEP_ACTIVE_LINE_HALF_VARIANTS,
  STEP_ACTIVE_LINE_FULL_VARIANTS,
} from '../Steps.constants';
import { AnimatedStepLine } from '../Steps.styles';

function Step({ isLast, completed, active, activeSize }: TStepProps) {
  function handleAnimateVariant() {
    if (activeSize === 'half') {
      return STEP_ACTIVE_LINE_HALF_VARIANTS;
    }
    if (activeSize === 'full') {
      return STEP_ACTIVE_LINE_FULL_VARIANTS;
    }
    return STEP_ACTIVE_LINE_DEFAULT_VARIANTS;
  }

  return (
    <Box
      position="relative"
      height={6}
      borderRadius={6}
      backgroundColor={completed ? 'stepScreenActiveLineBackground' : 'stepScreenLineBackground'}
      flex={1}
      overflow="hidden"
      mr={isLast ? 'none' : 'xs'}>
      {active && (
        <AnimatedStepLine
          variants={handleAnimateVariant()}
          transition={{ duration: 0.75 }}
          initial="inactive"
          animate="active"
        />
      )}
    </Box>
  );
}

export default Step;
