import React from 'react';

import Box from 'components/core/Box';
import Dot from 'components/v2/core/Dot';

function MigrationBadgeDot() {
  return (
    <Box
      display="flex"
      position="relative"
      alignItems="center"
      justifyContent="center"
      width={16}
      height={16}
      borderRadius={16}
      backgroundColor="statusWarningPrimary"
      borderWidth="2px"
      borderColor="borderOutline">
      <Dot size={4} color="textInverted" />
    </Box>
  );
}

export default MigrationBadgeDot;
