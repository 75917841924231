import React from 'react';

import Pressable from 'components/v2/core/Pressable';
import Avatar from 'components/v2/core/Avatar';
import Text from 'components/v2/core/Text';
import Box from 'components/core/Box';
import { XCloseIcon } from 'components/v2/Icons';

import { TAvatarImageButtonProps } from './AvatarImageButton.types';

function AvatarImageButton({
  imageUrl,
  onPressPreview,
  onPressRemove,
  badgeCount,
  removeLoading,
}: TAvatarImageButtonProps) {
  function renderBadge() {
    if (onPressRemove) {
      return (
        <Pressable onClick={onPressRemove} disabled={removeLoading}>
          <Box
            position="absolute"
            top={0}
            right={0}
            width={16}
            height={16}
            borderRadius={16}
            alignItems="center"
            justifyContent="center"
            backgroundColor="statusErrorGhost">
            <XCloseIcon width={10} height={10} strokeWidth={4} iconColor="statusErrorPrimary" />
          </Box>
        </Pressable>
      );
    }
    if (badgeCount) {
      return (
        <Box
          width={16}
          height={16}
          borderRadius={16}
          display="flex"
          position="absolute"
          top={0}
          right={0}
          alignItems="center"
          justifyContent="center"
          backgroundColor="buttonPrimary">
          <Text variant="textBodySmallBold" color="textInverted">
            {badgeCount}
          </Text>
        </Box>
      );
    }
    return <></>;
  }

  return (
    <Pressable onClick={onPressPreview}>
      <Box position="relative">
        <Avatar imageUrl={imageUrl} size="small" imageResizeMode="cover" />
        {renderBadge()}
      </Box>
    </Pressable>
  );
}

export default AvatarImageButton;
