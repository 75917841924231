import {
  TTextLinkGetStateStyleProps,
  TTextLinkGetStyleReturn,
  TTextLinkGetSizeProps,
  TTextLinkSizeProperty,
} from './TextLink.types';
import { TEXT_LINK_SIZE_MAP, TEXT_LINK_VARIANT_STATE_MAP } from './TextLink.constants';

export function getTextLinkStateStyle({
  pressed,
  disabled,
  loading,
  variant,
}: TTextLinkGetStateStyleProps): TTextLinkGetStyleReturn {
  const textLinkStateMap = TEXT_LINK_VARIANT_STATE_MAP[variant];
  if (disabled || loading) {
    return textLinkStateMap.disabled;
  }
  if (pressed) {
    return textLinkStateMap.onTap;
  }
  return textLinkStateMap.default;
}

export function getTextLinkSize({ size }: TTextLinkGetSizeProps): TTextLinkSizeProperty {
  return TEXT_LINK_SIZE_MAP[size];
}
