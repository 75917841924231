import React from 'react';

import Box from 'components/core/Box';

import Step from './views/Step';
import { TStepsProps } from './Steps.types';

function Steps({ count, activeStep, activeSize }: TStepsProps) {
  return (
    <Box alignment="row.verticalCenter" my="xxs">
      {[...Array(count)].map((_, i) => (
        <Step
          key={i.toString()}
          isLast={i + 1 === count}
          active={i < activeStep}
          completed={i < activeStep - 1}
          activeSize={activeSize}
        />
      ))}
    </Box>
  );
}

export default Steps;
