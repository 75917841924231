import React from 'react';

import Card from 'components/core/Card';
import Box from 'components/core/Box';
import TextLink from 'components/v2/core/TextLink';
import { Trash01Icon, EyeIcon } from 'components/v2/Icons';

import downloadImage from 'helpers/downloadImage';

import { TTransactionDetailMerchantDocumentItemProps } from '../TransactionDetailMerchantDocumentsPreviewModal.types';

function TransactionDetailMerchantDocumentItem({
  document,
  onRemove,
  loading,
}: TTransactionDetailMerchantDocumentItemProps) {
  return (
    <Card mb="m" p="m">
      <img src={document.uri} alt="document" />
      <Box display="flex" flexDirection="row" justifyContent="space-between" gap="m" mt="s">
        <TextLink
          labelId="button.open"
          leftIcon={EyeIcon}
          bottomOutline={false}
          onPress={() => downloadImage(document.uri, document.name)}
          loading={loading}
        />
        <TextLink
          labelId="button.remove"
          leftIcon={Trash01Icon}
          bottomOutline={false}
          onPress={onRemove}
          loading={loading}
          variant="danger"
        />
      </Box>
    </Card>
  );
}

export default TransactionDetailMerchantDocumentItem;
