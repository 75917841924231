import React, { useImperativeHandle, useRef, useState } from 'react';

import { useModal } from 'components/core/Modal';
import PageModal from 'components/PageModal';

import { TCardReset3DSecurityAuthenticationModalRef } from 'modals/CardReset3DSecurityAuthenticationModal/CardReset3DSecurityAuthenticationModal.types';
import CardReset3DSecurityAuthenticationModal from 'modals/CardReset3DSecurityAuthenticationModal';

import { getLabelIdByCard3DSType } from 'enums/Card3DSType';
import { TCard } from 'types/card';

import {
  TCardReset3DSecurityModalProps,
  TModalState,
  TResetCard3DSFormData,
} from './CardReset3DSecurityModal.types';
import ModalContent from './views/ModalContent';

function CardReset3DSecurityModal({ modalRef }: TCardReset3DSecurityModalProps) {
  const ref = useModal();
  const [state, setState] = useState<TModalState>();
  const cardReset3DSecurityAuthenticationModalRef =
    useRef<TCardReset3DSecurityAuthenticationModalRef>(null);

  useImperativeHandle(
    modalRef,
    () => ({
      show: (state) => {
        setState(state);
        ref.current?.show(true);
      },
    }),
    []
  );

  function hideModal() {
    ref.current?.show(false);
  }

  function openAuthenticationModal(values: TResetCard3DSFormData) {
    cardReset3DSecurityAuthenticationModalRef.current?.show({
      card: state?.card as TCard,
      values,
      refetchCardDetail: state?.refetchCardDetail,
    });
  }

  return (
    <>
      <PageModal
        headerTitleId={
          state
            ? getLabelIdByCard3DSType(state.card.threedSecurityMethod.type)
            : 'placeholder.empty'
        }
        afterClose={() => setState(undefined)}
        modalRef={ref}
        sideLayoutVisible={false}
        shouldHideSideLayoutOnVisibilityChange={false}
        isStaticSmallHeader>
        {state && (
          <ModalContent
            card={state.card}
            staticPasswordPageHeaderProps={state.staticPasswordPageHeaderProps}
            onSubmit={openAuthenticationModal}
          />
        )}
      </PageModal>
      <CardReset3DSecurityAuthenticationModal
        modalRef={cardReset3DSecurityAuthenticationModalRef}
        onSuccess={hideModal}
      />
    </>
  );
}

export default CardReset3DSecurityModal;
