import React, { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { isNil } from 'lodash/fp';

import SideLayout from 'layouts/SideLayout';

import { TDashboardLayoutProps } from './DashboardLayout.types';
import {
  StyledLayout,
  StyledContainer,
  StyledWrapper,
  StyledContent,
  StyledMain,
} from './DashboardLayout.styles';
import DashboardLayoutNav from './views/DashboardLayoutNav';

function DashboardLayout({
  children,
  visibleMenu,
  isPageModal,
  navMenu,
  activeParentStepIndex,
  activeChildStepIndex,
  hideProgressSteps,
  showMobileNav,
  shouldHideSideLayoutOnVisibilityChange,
  sideLayoutVisible = true,
}: TDashboardLayoutProps): ReactElement {
  return (
    <>
      <Helmet>
        <title>wamo business</title>
        <meta name="description" content="wamo business dashboard" />
      </Helmet>
      <StyledLayout>
        <StyledContainer>
          <StyledWrapper>
            <StyledContent
              visibleMenu={visibleMenu}
              isProgressSteps={!isNil(activeParentStepIndex)}>
              {visibleMenu && (
                <DashboardLayoutNav
                  navMenu={navMenu}
                  activeParentStepIndex={activeParentStepIndex}
                  activeChildStepIndex={activeChildStepIndex}
                  hideProgressSteps={hideProgressSteps}
                  showMobileNav={showMobileNav}
                />
              )}
              <StyledMain isPageModal={isPageModal}>{children || <Outlet />}</StyledMain>
              {sideLayoutVisible && (
                <SideLayout
                  shouldHideSideLayoutOnVisibilityChange={shouldHideSideLayoutOnVisibilityChange}
                />
              )}
            </StyledContent>
          </StyledWrapper>
        </StyledContainer>
      </StyledLayout>
    </>
  );
}

export default DashboardLayout;
