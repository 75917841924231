import React, { useMemo, useRef } from 'react';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Text from 'components/core/Text';
import Card from 'components/core/Card';
import Box from 'components/core/Box';
import InfoItem from 'components/core/InfoItem';
import { ArrowsRightIcon, InformationIcon } from 'components/Icons';
import LedgerEntrySideLayoutPage from 'components/LedgerEntrySideLayoutPage';
import FeeDetailModal from 'modals/FeeDetailModal';
import Status from 'components/v2/Status';
import LedgerItem from 'components/LedgerItem';
import ListItem from 'components/core/ListItem';

import { TCommonResponse } from 'types/common';
import { TFeeDetail, TTransactionDetail } from 'types/transaction';
import { getLabelIdByTransactionType } from 'enums/TransactionType';
import TransactionStatus from 'enums/TransactionStatus';
import replaceUrlParams from 'helpers/replaceUrlParams';
import API_ENDPOINT from 'constants/apiEndpoint';
import { formatAmount } from 'helpers/formatAmount';
import toPath from 'helpers/toPath';
import { TFeeDetailModalRef } from 'modals/FeeDetailModal/FeeDetailModal.types';
import routes from 'router/routes';
import getTransactionExchangeAmount from 'helpers/getTransactionExchangedAmount';
import { getTransactionStatusProps } from 'helpers/transaction';

import ExchangeDetail from './views/ExchangeDetail';
import TransactionDetailMerchantDocuments from './views/TransactionDetailMerchantDocuments';
import { TTransactionDetailProps } from './TransactionDetail.types';

function TransactionDetail({ id }: TTransactionDetailProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data,
    loading,
    get: requestTransactionDetail,
  } = useFetch<TCommonResponse<TTransactionDetail>>(
    replaceUrlParams(API_ENDPOINT.TRANSACTION_DETAIL, {
      id,
    }),
    [id]
  );
  const feeDetailModalRef = useRef<TFeeDetailModalRef>(null);
  const transactionDetail = useMemo(() => data?.result, [data]);

  if (loading || !transactionDetail) {
    return <LedgerEntrySideLayoutPage.Shimmer />;
  }

  const shouldShowDescription = transactionDetail.description;

  const shouldShowStatement = [
    TransactionStatus.ACCEPTED,
    TransactionStatus.APPROVED,
    TransactionStatus.PENDING,
  ].includes(transactionDetail.status);

  const lineThroughAmount = [TransactionStatus.REVERSED, TransactionStatus.DECLINED].includes(
    transactionDetail.status
  );

  return (
    <LedgerEntrySideLayoutPage
      amount={transactionDetail.amount}
      currency={transactionDetail.currency}
      direction={transactionDetail.direction}
      title={transactionDetail.title}
      senderInfo={transactionDetail.sender}
      createdAt={transactionDetail.createdAt}
      lineThroughAmount={lineThroughAmount}
      statementApiEndpoint={replaceUrlParams(API_ENDPOINT.TRANSACTION_STATEMENT, { id })}
      statementVisible={shouldShowStatement}>
      {transactionDetail.card && (
        <Card paddingX="m" marginTop="m">
          <InfoItem titleId="label.cardName" value={transactionDetail.card.label} />
          <InfoItem titleId="label.card" value={`**${transactionDetail.card.truncatedPan}`} />
          <InfoItem
            titleId="label.merchantName"
            value={transactionDetail.card.rawCardMerchantName}
          />
        </Card>
      )}
      <Card paddingX="m" marginTop="m">
        <InfoItem
          titleId="label.transactionType"
          value={t(getLabelIdByTransactionType(transactionDetail.type))}
        />
        {!!transactionDetail.convertedAmount && (
          <InfoItem
            titleId="label.convertedAmount"
            value={`${transactionDetail.convertedCurrency?.symbol}${transactionDetail.convertedAmount}`}
          />
        )}
        <InfoItem
          titleId="label.status"
          value={<Status {...getTransactionStatusProps(transactionDetail)} />}
        />
        {transactionDetail.feeDetail && (
          <InfoItem
            titleId="label.fee"
            value={
              <Box alignment="row.verticalCenter">
                <Text variant="largeTextSemiBold" mr="xs">
                  {formatAmount({
                    amount: transactionDetail.feeDetail.amount,
                    currency: transactionDetail.feeDetail.currency,
                  })}
                </Text>
                <InformationIcon
                  onClick={() =>
                    feeDetailModalRef.current?.show({
                      feeDetail: transactionDetail.feeDetail as TFeeDetail,
                    })
                  }
                  iconColor="tertiary"
                  width="20px"
                  height="20px"
                  cursor="pointer"
                />
              </Box>
            }
          />
        )}
        {transactionDetail.fxOrder && (
          <ExchangeDetail
            detail={transactionDetail.fxOrder}
            formattedExchangeAmount={getTransactionExchangeAmount({
              direction: transactionDetail.direction,
              fxOrder: transactionDetail.fxOrder,
              directionSymbolVisible: false,
            })}
          />
        )}
      </Card>
      {shouldShowDescription && (
        <Card paddingX="m" marginTop="m">
          <InfoItem titleId="label.description" value={transactionDetail.description} />
        </Card>
      )}
      <TransactionDetailMerchantDocuments
        transactionId={transactionDetail.id}
        onRequestTransactionDetail={requestTransactionDetail}
        documents={transactionDetail.merchantDocuments ?? []}
        merchantData={transactionDetail.merchantData}
      />
      <Card p="m" mt="m">
        <Text id="label.sourceAccount" variant="smallTextSemiBold" mb="sm" color="infoItemTitle" />
        <LedgerItem ledger={transactionDetail.ledger} hideBalance />
      </Card>
      {transactionDetail.isBulkTransfer && (
        <ListItem
          titleId="label.bulkTransfer"
          description={{
            id: 'label.transfers.title.withCount',
            values: {
              count: transactionDetail.bulkTransferItemCount,
            },
          }}
          rightIcon={<ArrowsRightIcon iconColor="tertiary" />}
          onClick={() =>
            navigate(
              toPath(routes.bulkTransferDetail.path, {
                id: transactionDetail?.bulkTransferBatchId,
              })
            )
          }
          mt="m"
        />
      )}
      <FeeDetailModal modalRef={feeDetailModalRef} />
    </LedgerEntrySideLayoutPage>
  );
}

export default TransactionDetail;
