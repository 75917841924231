import React, { useState } from 'react';
import { useFetch } from 'use-http';
import * as Sentry from '@sentry/react';

import { FileCheck03Icon, Trash01Icon } from 'components/v2/Icons';

import normalizeUrl from 'helpers/normalizeUrl';
import API_ENDPOINT from 'constants/apiEndpoint';
import { showSuccessAlert, showErrorAlert } from 'helpers/showAlertModal';

import {
  TTransactionDetailMerchantDataDescriptionModalFormData,
  TUseAddOrUpdateMerchantDataDescriptionParams,
  TUseDeleteMerchantDataDescriptionParams,
} from './TransactionDetailMerchantDataDescriptionModal.types';

export function useAddOrUpdateMerchantDataDescription({
  transactionId,
  merchantDataId,
  onSuccess,
}: TUseAddOrUpdateMerchantDataDescriptionParams) {
  const [addOrUpdateLoading, setAddOrUpdateLoading] = useState<boolean>(false);
  const { post: requestAddMerchantDataDescription, response: addResponse } = useFetch(
    normalizeUrl(API_ENDPOINT.TRANSACTION_DETAIL_MERCHANT_DATA, {
      pathVariables: {
        transactionId,
      },
    })
  );
  const { put: requestUpdateMerchantDataDescription, response: updateResponse } = useFetch(
    normalizeUrl(API_ENDPOINT.TRANSACTION_DETAIL_MERCHANT_DATA_DETAIL, {
      pathVariables: {
        transactionId,
        merchantDataId: merchantDataId as string,
      },
    })
  );

  async function handleAddOrUpdate({
    description,
  }: TTransactionDetailMerchantDataDescriptionModalFormData) {
    try {
      setAddOrUpdateLoading(true);
      const requestUserData = merchantDataId
        ? requestUpdateMerchantDataDescription
        : requestAddMerchantDataDescription;

      const data = await requestUserData({
        description,
      });
      if (updateResponse.ok || addResponse.ok) {
        const messageLabelId = merchantDataId
          ? 'messages.transactionDetailMerchantData.update.title'
          : 'messages.transactionDetailMerchantData.add.title';
        showSuccessAlert({
          titleId: messageLabelId,
          icon: FileCheck03Icon,
        });
        return await onSuccess();
      }
      return showErrorAlert({
        titleId: data?.errorMessage,
      });
    } catch (e) {
      Sentry.captureException(e);
      return showErrorAlert({ titleId: 'errors.unexpectedError' });
    } finally {
      setAddOrUpdateLoading(false);
    }
  }

  return {
    addOrUpdateUserDataDescription: handleAddOrUpdate,
    addOrUpdateLoading,
  };
}

export function useDeleteMerchantDataDescription({
  transactionId,
  merchantDataId,
  onSuccess,
  popconfirmRef,
}: TUseDeleteMerchantDataDescriptionParams) {
  const [loading, setLoading] = useState<boolean>(false);
  const { delete: requestDeleteMerchantDataDescription, response } = useFetch(
    normalizeUrl(API_ENDPOINT.TRANSACTION_DETAIL_MERCHANT_DATA_DETAIL, {
      pathVariables: {
        transactionId,
        merchantDataId,
      },
    })
  );

  function showDeleteConfirmationModal() {
    popconfirmRef.current?.show({
      titleId: 'messages.deleteTransactionDetailMerchantData.title',
      contentIcon: <Trash01Icon width={64} height={64} iconColor="statusErrorPrimary" />,
      onConfirm: handleDelete,
    });
  }

  async function handleDelete() {
    try {
      setLoading(true);
      const data = await requestDeleteMerchantDataDescription();
      if (!response.ok) {
        return showErrorAlert({
          titleId: data?.errorMessage,
        });
      }
      return await onSuccess();
    } catch (e) {
      Sentry.captureException(e);
      return showErrorAlert({ titleId: 'errors.unexpectedError' });
    } finally {
      setLoading(false);
    }
  }

  return {
    showDeleteConfirmationModal,
    deleteLoading: loading,
  };
}
