import React from 'react';

import Box from 'components/core/Box';
import Text from 'components/v2/core/Text';

import { TInfoBannerProps } from './InfoBanner.types';
import {
  INFO_BANNER_BORDER_RADIUS,
  INFO_BANNER_ICON_SIZE,
  INFO_BANNER_MIN_HEIGHT,
  INFO_BANNER_VARIANT_MAP,
} from './InfoBanner.constants';
import { InfoBannerContainer } from './InfoBanner.styles';

function InfoBanner({
  labelId,
  labelValues,
  labelProps,
  descriptionId,
  variant,
  icon,
  onClick,
}: TInfoBannerProps) {
  const { color, defaultIcon, backgroundColor } = INFO_BANNER_VARIANT_MAP[variant];
  const Icon = icon || defaultIcon;

  return (
    <InfoBannerContainer onClick={onClick} clickable={!!onClick}>
      <Box
        display="flex"
        flexDirection="row"
        p="m"
        pb={descriptionId ? 's' : 'm'}
        backgroundColor={backgroundColor}
        borderRadius={INFO_BANNER_BORDER_RADIUS}
        minHeight={INFO_BANNER_MIN_HEIGHT}>
        <Box display="flex" alignItems="stretch">
          <Icon width={INFO_BANNER_ICON_SIZE} height={INFO_BANNER_ICON_SIZE} iconColor={color} />
        </Box>
        <Box
          display="flex"
          alignItems="stretch"
          justifyContent="center"
          ml="s"
          flex={1}
          flexDirection="column">
          <Text
            textId={labelId}
            values={labelValues}
            variant="textBodySubBold"
            color={color}
            {...labelProps}
          />
          {descriptionId && (
            <Text
              className="info-banner-description"
              textId={descriptionId}
              variant="textBodySmallBold"
              color="statusNaturalPrimary"
            />
          )}
        </Box>
      </Box>
    </InfoBannerContainer>
  );
}

export default InfoBanner;
