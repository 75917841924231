import React, { memo } from 'react';

import ListItem from 'components/core/ListItem';
import LedgerIcon from 'components/LedgerIcon';
import Box from 'components/core/Box';

import { formatAmount } from 'helpers/formatAmount';
import ibanFormatter from 'helpers/ibanFormatter';
import { TI18nId } from 'types/common';
import LedgerStatus from 'enums/LedgerStatus';

import { TBankAccountItemProps } from './BankAccountItem.types';

function BankAccountItem({
  item,
  isSelected,
  onSelect,
  hideBalance,
  ...listItemProps
}: TBankAccountItemProps) {
  const isCreating = item.status === LedgerStatus.PENDING;

  function renderDescription() {
    if (isCreating) {
      return 'label.creating';
    }
    const accountInfo = item.iban
      ? ibanFormatter(item.iban as string)
      : item.ukAccountNumber ?? item.usAccountNumber;
    return `${
      hideBalance
        ? item.currency.code
        : formatAmount({
            amount: item.balance,
            currency: item.currency,
          })
    } - ${accountInfo}` as TI18nId;
  }

  return (
    <Box opacity={listItemProps.disabled ? 0.4 : 1}>
      <ListItem
        titleId={item.name as TI18nId}
        aria-pressed={isSelected}
        description={{
          id: renderDescription(),
          color: isCreating ? 'warning' : 'textSecondary',
        }}
        leftIcon={
          <LedgerIcon
            size={40}
            currencyCode={item.currency.code}
            isMainLedger={item.isMainLedger}
            isMarkedForClosure={item.isMarkedForClosure}
          />
        }
        marginX="none"
        onClick={() => onSelect?.(item)}
        {...listItemProps}
      />
    </Box>
  );
}

export default memo(BankAccountItem);
