import React from 'react';

import Header from 'components/Header';
import Text from 'components/core/Text';

import { TPageProps } from './Page.types';

function Page({
  titleId,
  titleProps,
  descriptionId,
  descriptionProps,
  navButton,
  children,
  steps,
  headerRight,
}: TPageProps) {
  const hasDescription = descriptionId || descriptionProps;
  return (
    <>
      <Header navButton={navButton} headerRight={headerRight} steps={steps} />
      {titleId && (
        <Text
          className="page-title"
          id={titleId}
          {...titleProps}
          variant="largeTitleBold"
          mb={hasDescription ? 's' : 'l'}
        />
      )}
      {hasDescription && (
        <Text
          id={descriptionId}
          {...(descriptionProps as unknown as Record<string, unknown>)}
          variant="largeTextRegular"
          mb="l"
        />
      )}
      {children}
    </>
  );
}

export default Page;
