import React from 'react';

import Box from 'components/core/Box';
import Text from 'components/core/Text';
import { LeftArrowIcon, CloseIcon } from 'components/Icons';
import Steps from 'components/core/Steps';

import ZIndex from 'constants/zIndex';
import { StyledBaseButton } from 'components/core/Button/Button.styles';
import { THeaderProps } from './Header.types';

function Header({ navButton, headerRight, steps }: THeaderProps) {
  return (
    <Box
      mb="s"
      position="sticky"
      top={0}
      backgroundColor="primaryBackground"
      zIndex={ZIndex.STICKY_HEADER}>
      <Box alignment="row.top.left" width="100%" height="56px">
        {navButton && (
          <Box alignment="row.verticalCenter" flex={1} height="56px">
            <Box flex={1}>
              {navButton.onClick && (
                <StyledBaseButton type="button" onClick={navButton.onClick}>
                  {navButton.closeIconButton ? (
                    <CloseIcon width={32} height={32} />
                  ) : (
                    <LeftArrowIcon width={32} height={32} />
                  )}
                  {navButton.backTitleId && (
                    <Text
                      id={navButton.backTitleId}
                      variant="mediumTextBold"
                      ml="s"
                      color="headerBackTitle"
                    />
                  )}
                </StyledBaseButton>
              )}
            </Box>
            {navButton.cancelButton}
          </Box>
        )}
        <Box alignment="row.verticalCenter" height="56px">
          {headerRight && <Box>{headerRight}</Box>}
        </Box>
      </Box>
      {steps && steps.count > 1 && (
        <Box height="16px" mt="m">
          <Steps {...steps} />
        </Box>
      )}
    </Box>
  );
}

export default Header;
