import React, { useImperativeHandle, forwardRef, useRef, useState } from 'react';
import { useFetch } from 'use-http';
import * as Sentry from '@sentry/react';
import { isEmpty } from 'lodash/fp';

import { Trash01Icon } from 'components/v2/Icons';
import PageModal from 'components/v2/PageModal';

import PopconfirmModal from 'modals/PopconfirmModal';

import API_ENDPOINT from 'constants/apiEndpoint';
import normalizeUrl from 'helpers/normalizeUrl';
import { showErrorAlert } from 'helpers/showAlertModal';
import { useModal } from 'components/core/Modal';
import { TPopconfirmModalRef } from 'modals/PopconfirmModal/PopconfirmModal.types';

import TransactionDetailMerchantDocumentItem from './views/TransactionDetailMerchantDocumentItem';
import {
  TTransactionDetailMerchantDocumentsPreviewModalProps,
  TTransactionDetailMerchantDocumentsPreviewModalState,
} from './TransactionDetailMerchantDocumentsPreviewModal.types';

function TransactionDetailMerchantDocumentsPreviewModal({
  modalRef,
}: TTransactionDetailMerchantDocumentsPreviewModalProps) {
  const ref = useModal();
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState<TTransactionDetailMerchantDocumentsPreviewModalState>();
  const popconfirmModalRef = useRef<TPopconfirmModalRef>(null);
  const { delete: requestDeleteDocument, response } = useFetch(
    normalizeUrl(API_ENDPOINT.TRANSACTION_DETAIL_MERCHANT_DOCUMENTS, {
      pathVariables: {
        transactionId: state?.transactionId as string,
      },
    })
  );
  const [documents, setDocuments] = useState(state?.documents ?? []);

  useImperativeHandle(modalRef, () => ({
    show: (state: TTransactionDetailMerchantDocumentsPreviewModalState) => {
      setState(state);
      setDocuments(state?.documents ?? []);
      ref.current?.show(true);
    },
  }));

  function showDeleteDocumentConfirmationModal(id: string) {
    popconfirmModalRef.current?.show({
      titleId: 'messages.deleteTransactionDetailMerchantData.title',
      contentIcon: <Trash01Icon width={64} height={64} iconColor="statusErrorPrimary" />,
      onConfirm: async () => {
        await handleDeleteDocument(id);
      },
    });
  }

  async function handleDeleteDocument(id: string) {
    try {
      setLoading(true);
      const data = await requestDeleteDocument(`/${id}`);

      if (!response.ok) {
        return showErrorAlert({ titleId: data?.errorMessage });
      }
      const updatedDocumentsState = documents.filter((document) => document.id !== id);
      await state?.onRequestTransactionDetail();

      if (isEmpty(updatedDocumentsState)) {
        ref.current?.show(false);
      } else {
        setDocuments(updatedDocumentsState);
      }
      return undefined;
    } catch (e) {
      Sentry.captureException(e);
      return showErrorAlert({
        titleId: 'errors.unexpectedError',
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <PageModal modalRef={ref} shouldHideSideLayoutOnVisibilityChange={false}>
      {documents.map((document) => (
        <TransactionDetailMerchantDocumentItem
          key={document.id}
          document={document}
          onRemove={() => showDeleteDocumentConfirmationModal(document.id as string)}
          loading={loading}
        />
      ))}
      <PopconfirmModal ref={popconfirmModalRef} />
    </PageModal>
  );
}

export default forwardRef(TransactionDetailMerchantDocumentsPreviewModal);
