import React, { createContext, useContext, useEffect, useState } from 'react';
import { useFetch } from 'use-http';

import { TChildrenOnly, TCommonResponse } from 'types/common';

import API_ENDPOINT from 'constants/apiEndpoint';

import {
  TMerchantMigrationContext,
  TMerchantMigrationResponse,
  TMerchantMigrationState,
} from './MerchantMigrationProvider.types';
import { INITIAL_DISPATCH, INITIAL_STATE } from './MerchantMigrationProvider.constants';

const merchantMigrationContext = createContext<TMerchantMigrationContext>({
  merchantMigrationState: INITIAL_STATE,
  merchantMigrationDispatch: INITIAL_DISPATCH,
});

function MerchantMigrationProvider({ children }: TChildrenOnly) {
  const {
    get: requestMerchantMigration,
    data,
    response,
  } = useFetch<TCommonResponse<TMerchantMigrationResponse>>(API_ENDPOINT.MERCHANT_MIGRATION);
  const [state, setState] = useState<TMerchantMigrationState>(INITIAL_STATE);

  useEffect(() => {
    if (data?.result) {
      setState(data.result);
    }
  }, [data]);

  async function getMerchantMigration() {
    const merchantMigrationData = await requestMerchantMigration();
    if (response.ok) {
      return merchantMigrationData.result;
    }
    return undefined;
  }

  function clear() {
    setState(INITIAL_STATE);
  }

  const value: TMerchantMigrationContext = {
    merchantMigrationState: state,
    merchantMigrationDispatch: {
      getMerchantMigration,
      clear,
    },
  };

  return (
    <merchantMigrationContext.Provider value={value}>{children}</merchantMigrationContext.Provider>
  );
}

export default MerchantMigrationProvider;

export const useMerchantMigrationContext = (): TMerchantMigrationContext =>
  useContext(merchantMigrationContext);
