import styled from 'styled-components';

import { getColor } from 'helpers/theme';

export const InfoBannerContainer = styled.button<{ clickable: boolean }>`
  width: 100%;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};

  :hover {
    .info-banner-description {
      color: ${getColor('statusNaturalPrimaryOnTap')};
    }
  }
`;
