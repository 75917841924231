import React, { useEffect, useRef, useState } from 'react';
import { isEmpty, last } from 'lodash/fp';
import { useFetch } from 'use-http';
import * as Sentry from '@sentry/react';
import { useDropzone } from 'react-dropzone';

import Box from 'components/core/Box';
import IconButton from 'components/v2/core/IconButton';
import AvatarImageButton from 'components/v2/AvatarImageButton';
import { File04Icon, Trash01Icon, Upload04Icon } from 'components/v2/Icons';
import { InfoItemButton } from 'components/v2/core/InfoItem';

import TransactionDetailMerchantDocumentsPreviewModal from 'modals/TransactionDetailMerchantDocumentsPreviewModal';

import API_ENDPOINT from 'constants/apiEndpoint';
import normalizeUrl from 'helpers/normalizeUrl';
import { showErrorAlert, showSuccessAlert } from 'helpers/showAlertModal';
import NOOP from 'constants/noop';
import { TTransactionDetailMerchantDocumentsPreviewModalRef } from 'modals/TransactionDetailMerchantDocumentsPreviewModal/TransactionDetailMerchantDocumentsPreviewModal.types';

import { TTransactionDetailUserDocumentsListProps } from '../TransactionDetail.types';

function TransactionDetailMerchantDocumentsList({
  transactionId,
  documents,
  onRequestTransactionDetail,
}: TTransactionDetailUserDocumentsListProps) {
  const transactionDetailMerchantDocumentsPreviewModalRef =
    useRef<TTransactionDetailMerchantDocumentsPreviewModalRef>(null);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const { getInputProps, getRootProps, acceptedFiles } = useDropzone({
    accept: ['image/*', 'application/pdf'],
    maxFiles: 1,
  });
  const { post: requestUploadDocument, response: uploadDocumentResponse } = useFetch(
    normalizeUrl(API_ENDPOINT.TRANSACTION_DETAIL_MERCHANT_DOCUMENTS, {
      pathVariables: {
        transactionId,
      },
    })
  );
  const { delete: requestDeleteDocument, response: deleteDocumentResponse } = useFetch(
    normalizeUrl(API_ENDPOINT.TRANSACTION_DETAIL_MERCHANT_DOCUMENTS, {
      pathVariables: {
        transactionId,
      },
    })
  );

  const lastDocument = last(documents);

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      handleUploadDocument(acceptedFiles);
    }
  }, [acceptedFiles]);

  async function handleUploadDocument(uploadDocuments: File[]) {
    try {
      setUploadLoading(true);
      const formData = new FormData();
      formData.append('file', uploadDocuments[0]);
      const data = await requestUploadDocument(formData);

      if (!uploadDocumentResponse.ok) {
        return showErrorAlert({
          titleId: data?.errorMessage,
        });
      }
      showSuccessAlert({
        titleId: 'messages.transactionDetailMerchantDocument.uploadSuccess.title',
        icon: File04Icon,
      });
      return await onRequestTransactionDetail();
    } catch (e) {
      Sentry.captureException(e);
      return showErrorAlert({ titleId: 'errors.unexpectedError' });
    } finally {
      setUploadLoading(false);
    }
  }

  async function handleDeleteDocument(id: string) {
    try {
      setDeleteLoading(true);
      const data = await requestDeleteDocument(`/${id}`);

      if (!deleteDocumentResponse.ok) {
        return showErrorAlert({ titleId: data?.errorMessage });
      }
      showSuccessAlert({
        titleId: 'messages.transactionDetailMerchantDocument.deleteSuccess.title',
        icon: Trash01Icon,
      });
      return await onRequestTransactionDetail();
    } catch (e) {
      Sentry.captureException(e);
      return showErrorAlert({
        titleId: 'errors.unexpectedError',
      });
    } finally {
      setDeleteLoading(false);
    }
  }

  return (
    <>
      <Box display="flex" flexDirection="row" gap="s">
        {documents.length > 2 && lastDocument ? (
          <AvatarImageButton
            imageUrl={lastDocument.uri as string}
            badgeCount={documents.length}
            onPressPreview={() =>
              transactionDetailMerchantDocumentsPreviewModalRef.current?.show({
                transactionId,
                documents,
                onRequestTransactionDetail,
              })
            }
          />
        ) : (
          <>
            {documents.map((document) => (
              <AvatarImageButton
                key={document.id}
                imageUrl={document.uri as string}
                onPressPreview={() =>
                  transactionDetailMerchantDocumentsPreviewModalRef.current?.show({
                    transactionId,
                    documents,
                    onRequestTransactionDetail,
                  })
                }
                onPressRemove={() => handleDeleteDocument(document.id as string)}
                removeLoading={deleteLoading}
              />
            ))}
          </>
        )}
        <Box ml="s">
          {isEmpty(documents) ? (
            <Box position="relative" {...getRootProps({ className: 'dropzone' })}>
              <InfoItemButton
                labelId="label.upload"
                icon={Upload04Icon}
                onPress={NOOP}
                loading={uploadLoading}
              />
              <input {...getInputProps()} />
            </Box>
          ) : (
            <Box position="relative" {...getRootProps({ className: 'dropzone' })}>
              <IconButton
                icon={Upload04Icon}
                size="mediumLarge"
                variant="ghost"
                onClick={NOOP}
                loading={uploadLoading}
              />
              <input {...getInputProps()} />
            </Box>
          )}
        </Box>
      </Box>
      <TransactionDetailMerchantDocumentsPreviewModal
        modalRef={transactionDetailMerchantDocumentsPreviewModalRef}
      />
    </>
  );
}

export default TransactionDetailMerchantDocumentsList;
