import React, { memo } from 'react';
import { useTheme } from 'styled-components';

import Box from 'components/core/Box';
import Badge from 'components/v2/core/Badge';
import { Snowflake01Icon } from 'components/v2/Icons';
import MigrationBadgeDot from 'components/MigrationBadgeDot';

import CardTopContent from './views/CardTopContent';
import CardBottomContent from './views/CardBottomContent';
import CardFreezeMask from './views/CardFreezeMask';
import { TWamoCardProps } from './WamoCard.types';
import {
  getWamoCardColorsByCardColorType,
  getWamoCardColorsByVariant,
  getWamoCardSize,
} from './WamoCard.helpers';

function WamoCard({
  size,
  cardType,
  nameOnCard,
  truncatedPan,
  isFrozen,
  variant = 'black',
  isCardTypeBadgeVisible = false,
  block,
  cardColorType,
  isAvailable = true,
  isMarkedForClosure,
}: TWamoCardProps) {
  const { colors } = useTheme();
  const {
    width: cardWidth,
    height: cardHeight,
    lineHeight,
    borderRadius,
    borderOutlineWidth,
    frozenIconSize,
  } = getWamoCardSize(size);
  const opacity = isFrozen ? 0.72 : 1;

  const { backgroundColor, lineColor } = cardColorType
    ? getWamoCardColorsByCardColorType(cardColorType)
    : getWamoCardColorsByVariant(variant);

  return (
    <Box position="relative">
      {isMarkedForClosure && (
        <Box position="absolute" top={-4} right={-4} zIndex={9}>
          <MigrationBadgeDot />
        </Box>
      )}
      <Box
        style={{ backgroundColor: colors[backgroundColor as never] ?? backgroundColor }}
        position="relative"
        overflow="hidden"
        width={block ? '100%' : (cardWidth as number)}
        borderRadius={borderRadius}
        borderWidth={borderOutlineWidth}
        borderColor="borderOutline"
        opacity={isAvailable ? 1 : 0.34}
        height={cardHeight}>
        <CardFreezeMask isFrozen={isFrozen}>
          <Box
            style={{ backgroundColor: lineColor }}
            width={cardWidth as number}
            opacity={opacity}
            height={lineHeight}
          />
          <CardTopContent
            cardType={cardType}
            opacity={opacity}
            variant={variant}
            size={size}
            isCardTypeBadgeVisible={isCardTypeBadgeVisible && isAvailable}
          />
          <CardBottomContent
            nameOnCard={nameOnCard}
            truncatedPan={truncatedPan}
            opacity={opacity}
            variant={variant}
            size={size}
          />
          {isFrozen && (
            <Box
              position="absolute"
              display="flex"
              top={0}
              right={0}
              left={0}
              bottom={0}
              alignItems="center"
              justifyContent="center"
              zIndex={9}>
              <Snowflake01Icon
                width={frozenIconSize}
                height={frozenIconSize}
                iconColor="whiteIcon"
              />
            </Box>
          )}
        </CardFreezeMask>
      </Box>
      {!isAvailable && (
        <Box position="absolute" right={16} top={24}>
          <Badge labelId="label.notAvailable" variant="danger" size="small" />
        </Box>
      )}
    </Box>
  );
}

export default memo(WamoCard);
