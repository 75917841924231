import React from 'react';

import Box from 'components/core/Box';
import ActivityIndicator from 'components/v2/core/ActivityIndicator';
import Text from 'components/v2/core/Text';
import Pressable from 'components/v2/core/Pressable';

import { getTextLinkSize, getTextLinkStateStyle } from './TextLink.helpers';
import { TTextLinkProps } from './TextLink.types';

function TextLink({
  labelId,
  labelValues,
  labelProps,
  disabled = false,
  loading = false,
  size = 'medium',
  leftIcon: LeftIcon,
  variant = 'natural',
  rightIcon: RightIcon,
  onPress,
  block,
  bottomOutline = true,
  autoHeight = true,
}: TTextLinkProps) {
  return (
    <Pressable onClick={onPress} disabled={disabled}>
      {({ pressed }) => {
        const textLinkStyle = getTextLinkStateStyle({ pressed, loading, disabled, variant });
        const textLinkSize = getTextLinkSize({ size });

        return (
          <Box
            display="flex"
            flexDirection="row"
            alignSelf={block ? 'center' : 'flex-start'}
            alignItems="center"
            {...(autoHeight && {
              minHeight: textLinkSize.minHeight,
            })}>
            {loading && (
              <Box
                mr="s"
                alignItems="center"
                justifyContent="center"
                width={textLinkSize.iconSize}
                height={textLinkSize.iconSize}>
                <ActivityIndicator color={textLinkStyle.text} />
              </Box>
            )}
            {!loading && LeftIcon && (
              <Box display="flex" alignItems="center" justifyContent="center" mr="s">
                <LeftIcon
                  width={textLinkSize.iconSize}
                  height={textLinkSize.iconSize}
                  iconColor={textLinkStyle.text}
                />
              </Box>
            )}
            <Box position="relative">
              <Text
                textId={labelId}
                values={labelValues}
                color={textLinkStyle.text}
                variant={textLinkSize.textVariant}
                numberOfLines={1}
                {...labelProps}
              />
              {(!pressed || !loading) && bottomOutline && (
                <Box backgroundColor={textLinkStyle.text} height="1px" />
              )}
            </Box>
            {RightIcon && (
              <Box display="flex" alignItems="center" justifyContent="center" ml="s">
                <RightIcon
                  width={textLinkSize.iconSize}
                  height={textLinkSize.iconSize}
                  iconColor={textLinkStyle.text}
                />
              </Box>
            )}
          </Box>
        );
      }}
    </Pressable>
  );
}

export default TextLink;
