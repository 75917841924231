import React from 'react';

import Page from 'components/v2/Page';
import Modal from 'components/core/Modal';

import DashboardLayout from 'layouts/DashboardLayout';
import { NavMenu } from 'layouts/DashboardLayout/DashboardLayout.types';
import { useSideLayoutContext } from 'context/SideLayoutProvider';

import { TPageModalProps } from './PageModal.types';

function PageModal({
  modalRef,
  afterOpen,
  afterClose,
  isMenuVisible = false,
  isSideLayoutVisible = true,
  shouldHideSideLayoutOnVisibilityChange = true,
  titleId,
  titleProps,
  descriptionId,
  descriptionProps,
  steps,
  rightContent,
  headerRight,
  navButtonBackTitleId,
  children,
  className,
}: TPageModalProps) {
  const { sideLayoutDispatch } = useSideLayoutContext();

  function handleAfterOpen() {
    afterOpen?.();
    shouldHideSideLayoutOnVisibilityChange && sideLayoutDispatch.hideSideLayout();
  }

  function handleAfterClose() {
    afterClose?.();
    shouldHideSideLayoutOnVisibilityChange && sideLayoutDispatch.hideSideLayout();
  }

  function hideModal() {
    modalRef.current?.show(false);
  }

  return (
    <Modal
      ref={modalRef}
      afterOpen={handleAfterOpen}
      afterClose={handleAfterClose}
      className={className}
      isFullPage
      secondary>
      <DashboardLayout
        navMenu={NavMenu.HOME}
        isPageModal
        visibleMenu={isMenuVisible}
        shouldHideSideLayoutOnVisibilityChange={shouldHideSideLayoutOnVisibilityChange}
        sideLayoutVisible={isSideLayoutVisible}>
        <Page
          titleId={titleId}
          titleProps={titleProps}
          descriptionId={descriptionId}
          descriptionProps={descriptionProps}
          steps={steps}
          rightContent={rightContent}
          headerRight={headerRight}
          navButton={{
            onClick: hideModal,
            backTitleId: navButtonBackTitleId,
          }}>
          {children}
        </Page>
      </DashboardLayout>
    </Modal>
  );
}

export default PageModal;
