import React from 'react';

import ActivityIndicator from 'components/v2/core/ActivityIndicator';
import Pressable from 'components/v2/core/Pressable';
import Text from 'components/v2/core/Text';
import Box from 'components/core/Box';

import { TInfoItemButtonProps } from '../InfoItem.types';

function InfoItemButton({ labelId, icon: Icon, onPress, loading }: TInfoItemButtonProps) {
  return (
    <Pressable onClick={onPress}>
      {({ pressed }) => {
        const color = pressed ? 'statusNaturalPrimaryOnTap' : 'statusNaturalPrimary';

        return (
          <Box display="flex" flexDirection="row" alignItems="center" gap="xs">
            <Text textId={labelId} variant="textBodySub" color={color} />
            {loading ? (
              <Box width={24} height={24} alignItems="center" justifyContent="center">
                <ActivityIndicator color="statusNaturalPrimary" size={16} />
              </Box>
            ) : (
              <Icon width={24} height={24} iconColor={color} />
            )}
          </Box>
        );
      }}
    </Pressable>
  );
}

export default InfoItemButton;
