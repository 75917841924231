import React from 'react';
import { useNavigate } from 'react-router-dom';

import InfoBanner from 'components/v2/core/InfoBanner';
import Box from 'components/core/Box';
import { Announcement03Icon } from 'components/v2/Icons';
import Text from 'components/v2/core/Text';

import { useMerchantMigrationContext } from 'context/MerchantMigrationProvider';
import dateHelpers from 'helpers/date';
import routes from 'router/routes';

import { TMigrationBannerProps } from './MigrationBanner.types';

function MigrationBanner({ labelId, marginBottom = 'none' }: TMigrationBannerProps) {
  const { merchantMigrationState } = useMerchantMigrationContext();
  const navigate = useNavigate();

  if (!merchantMigrationState.showNotice) {
    return <></>;
  }

  return (
    <Box marginBottom={marginBottom}>
      <InfoBanner
        labelId={labelId}
        {...(merchantMigrationState?.finalDate && {
          labelValues: {
            finalDate: dateHelpers.format({
              date: merchantMigrationState.finalDate,
              formatType: 'dd.MM.yyyy',
            }),
          },
        })}
        labelProps={{
          variant: 'textBodySub',
          components: {
            b: <Text as="span" variant="textBodySubBold" color="statusWarningPrimary" />,
          },
        }}
        variant="warning"
        icon={Announcement03Icon}
        descriptionId="button.checkDetails"
        onClick={() => navigate(routes.migrationNotice.path)}
      />
    </Box>
  );
}

export default MigrationBanner;
