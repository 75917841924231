import React, { useRef } from 'react';

import Card from 'components/core/Card';
import Text from 'components/v2/core/Text';
import InfoItem, { InfoItemButton } from 'components/v2/core/InfoItem';
import { Pencil01Icon } from 'components/v2/Icons';
import Pressable from 'components/v2/core/Pressable';

import TransactionDetailMerchantDataDescriptionModal from 'modals/TransactionDetailMerchantDataDescriptionModal';

import { TTransactionDetailMerchantDataDescriptionModalRef } from 'modals/TransactionDetailMerchantDataDescriptionModal/TransactionDetailMerchantDataDescriptionModal.types';

import TransactionDetailMerchantDocumentsList from './TransactionDetailMerchantDocumentsList';
import { TTransactionDetailUserDocumentsProps } from '../TransactionDetail.types';

function TransactionDetailMerchantDocuments({
  transactionId,
  onRequestTransactionDetail,
  documents,
  merchantData,
}: TTransactionDetailUserDocumentsProps) {
  const transactionDetailMerchantDataDescriptionModalRef =
    useRef<TTransactionDetailMerchantDataDescriptionModalRef>(null);

  return (
    <>
      <Card display="grid" p="m" gap="m" mt="m">
        <InfoItem
          labelId="label.documents"
          value={
            <TransactionDetailMerchantDocumentsList
              transactionId={transactionId}
              documents={documents}
              onRequestTransactionDetail={onRequestTransactionDetail}
            />
          }
        />
        <InfoItem
          labelId="label.description"
          value={
            !merchantData?.id ? (
              <InfoItemButton
                labelId="label.add"
                icon={Pencil01Icon}
                onPress={() =>
                  transactionDetailMerchantDataDescriptionModalRef.current?.show({
                    merchantData,
                    transactionId,
                    onRequestTransactionDetail,
                  })
                }
              />
            ) : (
              <>
                <Pressable
                  onClick={() =>
                    transactionDetailMerchantDataDescriptionModalRef.current?.show({
                      merchantData,
                      transactionId,
                      onRequestTransactionDetail,
                    })
                  }>
                  <Text variant="textBodySub" color="statusNaturalPrimary">
                    {merchantData.description}
                  </Text>
                </Pressable>
              </>
            )
          }
          valueProps={{
            variant: 'textBodySub',
            color: 'statusNaturalPrimary',
          }}
        />
      </Card>
      <TransactionDetailMerchantDataDescriptionModal
        modalRef={transactionDetailMerchantDataDescriptionModalRef}
      />
    </>
  );
}

export default TransactionDetailMerchantDocuments;
