import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import CardItemBase from 'components/v2/core/CardItemBase';
import Text from 'components/v2/core/Text';
import WamoCard from 'components/WamoCard';

import { isCardFrozenOrFreezing } from 'enums/CardStatus';
import { getWamoCardVariantByColorType } from 'enums/CardColorType';
import { getCardBadgeLabelIdByCardType } from 'enums/CardType';

import CardItemDescription from './views/CardItemDescription';
import { TCardItemProps } from './CardItem.types';

function CardItem({ card, selected, onPress }: TCardItemProps) {
  const { t } = useTranslation();

  return (
    <CardItemBase
      titleId="label.cardLabel.withTypeAndLabel"
      titleProps={{
        values: {
          label: card.label,
          cardType: t(getCardBadgeLabelIdByCardType(card.type)),
        },
        components: {
          secondary: <Text as="span" variant="textBodySubBold" color="textSecondary" />,
        },
      }}
      icon={
        <WamoCard
          size="small"
          variant={getWamoCardVariantByColorType(card.color)}
          isFrozen={isCardFrozenOrFreezing(card.status)}
          isMarkedForClosure={card.isMarkedForClosure}
        />
      }
      description={
        <CardItemDescription
          truncatedPan={card.truncatedPan}
          cardHolder={card.cardHolder}
          status={card.status}
        />
      }
      gap="sm"
      {...(onPress && {
        onPress: () => onPress?.(card),
      })}
      selected={selected}
    />
  );
}

export default memo(CardItem);
