import React from 'react';

import { FieldSwitchButtonItem } from 'components/v2/Fields';
import Text from 'components/v2/core/Text';
import Box from 'components/core/Box';

import { TCardStaticPasswordBulkSwitchProps } from '../CardStaticPasswordForm.types';

function CardStaticPasswordBulkSwitch({
  labelId,
  name,
  control,
  errors,
  cardsCount,
  onPress,
  disabled,
}: TCardStaticPasswordBulkSwitchProps) {
  if (disabled) {
    return (
      <Box mb="m" width="100%">
        <Text
          textId={labelId}
          variant="textBodySub"
          onClick={onPress}
          values={{
            cardsCount,
          }}
          components={{
            textLink: (
              <Text
                as="span"
                color="statusNaturalPrimary"
                variant="textBodySubBold"
                onClick={onPress}
              />
            ),
          }}
        />
      </Box>
    );
  }
  return (
    <Box mb="s" width="100%">
      <FieldSwitchButtonItem
        name={name}
        control={control}
        errors={errors}
        labelId={labelId}
        labelProps={{
          values: {
            cardsCount,
          },
          components: {
            textLink: (
              <Text
                as="span"
                color="statusNaturalPrimary"
                variant="textBodySubBold"
                onClick={onPress}
              />
            ),
          },
        }}
      />
    </Box>
  );
}

export default CardStaticPasswordBulkSwitch;
