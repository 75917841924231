import NOOP from 'constants/noop';

import {
  TMerchantMigrationDispatch,
  TMerchantMigrationState,
} from './MerchantMigrationProvider.types';

export const INITIAL_STATE: TMerchantMigrationState = {
  showNotice: false,
  finalDate: undefined,
};

export const INITIAL_DISPATCH: TMerchantMigrationDispatch = {
  getMerchantMigration: NOOP as TMerchantMigrationDispatch['getMerchantMigration'],
  clear: NOOP,
} as TMerchantMigrationDispatch;
