import styled from 'styled-components';
import { motion } from 'framer-motion';

import { getColor } from 'helpers/theme';

export const AnimatedStepLine = styled(motion.div)`
  height: 6px;
  border-radius: 6px;
  background-color: ${getColor('stepScreenActiveLineBackground')};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 0;
`;
