import {
  AlertCircleIcon,
  CheckVerified01Icon,
  Hourglass01Icon,
  InfoCircleIcon,
} from 'components/v2/Icons';

import { TInfoBannerVariantMap } from './InfoBanner.types';

export const INFO_BANNER_ICON_SIZE = 24;
export const INFO_BANNER_BORDER_RADIUS = 16;
export const INFO_BANNER_MIN_HEIGHT = 56;

export const INFO_BANNER_VARIANT_MAP: TInfoBannerVariantMap = {
  info: {
    color: 'statusNaturalPrimary',
    backgroundColor: 'statusNaturalGhost',
    defaultIcon: InfoCircleIcon,
  },
  success: {
    color: 'statusPositivePrimary',
    backgroundColor: 'statusPositiveGhost',
    defaultIcon: CheckVerified01Icon,
  },
  warning: {
    color: 'statusWarningPrimary',
    backgroundColor: 'statusWarningGhost',
    defaultIcon: Hourglass01Icon,
  },
  danger: {
    color: 'statusErrorPrimary',
    backgroundColor: 'statusErrorGhost',
    defaultIcon: AlertCircleIcon,
  },
};
