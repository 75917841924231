import React, { useRef } from 'react';

import MigrationBanner from 'components/MigrationBanner';
import Box from 'components/core/Box';
import InfoBanner from 'components/v2/core/InfoBanner';
import Text from 'components/v2/core/Text';
import { Announcement03Icon } from 'components/v2/Icons';

import CardReset3DSecurityModal from 'modals/CardReset3DSecurityModal';

import { TCardReset3DSecurityModalRef } from 'modals/CardReset3DSecurityModal/CardReset3DSecurityModal.types';

import { TCardDetailInformationBannersProps } from '../CardDetail.types';

function CardDetailInformationBanners({
  card,
  refetchCardDetail,
}: TCardDetailInformationBannersProps) {
  const cardReset3DSecurityModalRef = useRef<TCardReset3DSecurityModalRef>(null);

  return (
    <>
      {card.isMarkedForClosure && (
        <Box px="m">
          <MigrationBanner labelId="messages.card.migration" marginBottom="m" />
        </Box>
      )}
      {card.isStaticPasswordAutoGenerated && (
        <>
          <Box px="m" mb="m">
            <InfoBanner
              labelId="messages.cardDetail.autoGeneratedStaticPassword.info"
              labelProps={{
                variant: 'textBodySub',
                components: {
                  b: <Text variant="textBodySubBold" color="statusWarningPrimary" />,
                },
              }}
              variant="warning"
              icon={Announcement03Icon}
              descriptionId="button.setStaticPassword"
              onClick={() =>
                cardReset3DSecurityModalRef.current?.show({
                  card,
                  staticPasswordPageHeaderProps: {
                    titleId: 'screens.cardSetBulkStaticPassword.title',
                    descriptionId: 'screens.cardSetBulkStaticPassword.description',
                    submitButtonLabelId: 'button.setStaticPassword',
                  },
                  refetchCardDetail,
                })
              }
            />
          </Box>
          <CardReset3DSecurityModal modalRef={cardReset3DSecurityModalRef} />
        </>
      )}
    </>
  );
}

export default CardDetailInformationBanners;
