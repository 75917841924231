import React from 'react';
import { useNavigate } from 'react-router-dom';

import { TNavigationHeaderBaseButtonProps } from '../Navigation.types';
import { StyledNavigationBaseButton } from '../Navigation.styles';

function NavigationHeaderBaseButton({
  onPress,
  disabled,
  children,
}: TNavigationHeaderBaseButtonProps) {
  const navigate = useNavigate();

  function handleOnPress() {
    if (onPress) {
      onPress();
    } else {
      navigate(-1);
    }
  }

  return (
    <StyledNavigationBaseButton
      type="button"
      onClick={handleOnPress}
      disabled={disabled}
      alignRight>
      {children}
    </StyledNavigationBaseButton>
  );
}

export default NavigationHeaderBaseButton;
