import React from 'react';
import { Controller, UseControllerReturn } from 'react-hook-form';
import { getOr } from 'lodash/fp';

import Box from 'components/core/Box';
import FormErrorLabel from 'components/v2/core/FormErrorLabel';
import SwitchButtonItem from 'components/v2/core/SwitchButtonItem';

import { TI18nId } from 'types/common';

import { TFieldSwitchButtonItemProps } from './FieldSwitchButtonItem.types';

function FieldSwitchButtonItem({
  labelId,
  labelProps,
  name,
  control,
  errors,
}: TFieldSwitchButtonItemProps) {
  const errorMessageId = getOr(undefined, name, errors)?.message;

  function handleRender({ field }: UseControllerReturn) {
    const { value, onChange } = field;

    return (
      <SwitchButtonItem
        name={name}
        textId={labelId}
        value={value}
        onChange={onChange}
        textProps={labelProps}
      />
    );
  }

  return (
    <Box>
      <Controller name={name} control={control} render={handleRender} />
      {errorMessageId && <FormErrorLabel textId={errorMessageId as TI18nId} />}
    </Box>
  );
}

export default FieldSwitchButtonItem;
