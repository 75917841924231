import React, { useRef } from 'react';

import Button from 'components/v2/core/Button';
import Box from 'components/core/Box';

import CardReset3DSecurityModal from 'modals/CardReset3DSecurityModal';
import { TCardReset3DSecurityModalRef } from 'modals/CardReset3DSecurityModal/CardReset3DSecurityModal.types';

import { TCardSetStaticPasswordButtonProps } from '../CardDetail.types';

function CardSetStaticPasswordButton({
  card,
  refetchCardDetail,
}: TCardSetStaticPasswordButtonProps) {
  const cardReset3DSecurityModalRef = useRef<TCardReset3DSecurityModalRef>(null);

  return (
    <>
      <Box mb="l" px="m">
        <Button
          labelId="button.setStaticPassword"
          onClick={() =>
            cardReset3DSecurityModalRef.current?.show({
              card,
              staticPasswordPageHeaderProps: {
                titleId: 'screens.cardSetBulkStaticPassword.title',
                descriptionId: 'screens.cardSetBulkStaticPassword.description',
                submitButtonLabelId: 'button.setStaticPassword',
              },
              refetchCardDetail,
            })
          }
        />
      </Box>
      <CardReset3DSecurityModal modalRef={cardReset3DSecurityModalRef} />
    </>
  );
}

export default CardSetStaticPasswordButton;
